<template>
  <div>
    <swiper
      v-if="cheapestFare && visibleCheapestFare"
      id="slider-component"
      ref="swiperRef"
      class="swiper-multiple px-3"
      :class="`${visibleCheapestFare ? 'visible' : 'hidden'}`"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="(item, index) in cheapestFare[tabIndex]"
        :key="index"
        class="slide pt-50 pt-lg-1 pb-50 px-25"
        style="height: auto; width: auto !important"
      >
        <b-card
          no-body
          :class="`mb-0 d-flex-center justify-content-start mr-0 px-0 h-100 btn font-weight-bold
          ${
            searchFlightDateArray[tabIndex] === item.date
              ? 'border-warning'
              : 'border-transparent'
          }`"
          :style="`${
            new Date().getTime() - new Date(item.date).getTime() >
            24 * 60 * 60 * 1000
              ? 'cursor: not-allowed; opacity: 40%; color: #777;'
              : ''
          }
          ${
            searchFlightDateArray[tabIndex] === item.date
              ? 'background-color: #FFF8E7'
              : ''
          }`"
          @click="() => { if ( new Date().getTime() - new Date(item.date).getTime() < 24 * 60 * 60 * 1000) handleClick(item.date, tabIndex, index)}"
        >
          <h5 :class="`fw-800 text-center mb-0 ${ searchFlightDateArray[tabIndex] === item.date ? 'text-dark' : '' }`">
            {{ `${convertISODateTimeToLLLL(item.date).weekday}, ${convertISODateTimeToLLLL(item.date).dayAndMonth}` }}
          </h5>
          <div
            v-if="isShowCheapFare"
            class="d-flex-center"
          >
            <div v-if="statusJobDone">
              <div v-if="item.cheapestFare && item.cheapestFare.length">
                <div
                  v-for="(itemCheapestFare, indexCheapestFare) in isShowAll
                    ? item.cheapestFare.sort((a, b) => a.netFare - b.netFare)
                    : [
                      item.cheapestFare.sort(
                        (a, b) => a.netFare - b.netFare,
                      )[0],
                    ]"
                  :key="indexCheapestFare"
                  class="text-nowrap fw-700 d-flex justify-content-between align-items-center py-25 px-50 px-md-1 my-25"
                >
                  <span
                    class="mr-50 mb-0"
                    :class="isMobileView ? 'font-small-3' : 'font-medium-1'"
                    :style="resolveBorderColorByAirline(itemCheapestFare.airline)"
                  >
                    {{ itemCheapestFare.airline }}
                  </span>
                  <span
                    :class="`mb-0 text-dark
                    ${
                      searchFlightDateArray[tabIndex] === item.date
                        ? 'text-dark'
                        : 'text-muted'
                    }
                      ${isMobileView ? 'font-small-3' : 'font-medium-1'}
                    `"
                    :style="resolveBorderColorByAirline(itemCheapestFare.airline)"
                  >
                    {{ formatCurrency(itemCheapestFare.netFare) }}
                  </span>
                </div>
              </div>

              <h6
                v-else
                class="my-25 py-25"
              >
                {{ $t('flight.cheapestFareNotFound') }}
              </h6>
            </div>
            <h6
              v-else
              class="my-25 py-25"
            >
              {{ $t('loading') }}
            </h6>
          </div>
        </b-card>
      </swiper-slide>

      <!-- ANCHOR Arrows -->
      <div
        slot="button-prev"
        class="swiper-button-prev"
      >
        <b-button
          variant="white"
          class="rounded-circle border d-flex-center p-50 p-lg-75"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            class="text-body"
            size="30"
          />
        </b-button>
      </div>

      <div
        slot="button-next"
        class="swiper-button-next"
      >
        <b-button
          variant="white"
          class="rounded-circle border d-flex-center p-50 p-lg-75"
        >
          <feather-icon
            icon="ChevronRightIcon"
            class="text-body"
            size="30"
          />
        </b-button>
      </div>
    </swiper>

    <div
      v-if="cheapestFare && visibleCheapestFare && isShowCheapFare"
      class="w-100 d-flex-center"
    >
      <b-button
        v-if="visibleCheapestFare"
        variant="flat-warning"
        class="d-flex-center py-25 rounded-lg"
        size="sm"
        @click="handleShowHide"
      >
        <feather-icon
          v-if="!isShowAll"
          icon="ChevronDownIcon"
          size="18"
        />
        <feather-icon
          v-else
          icon="ChevronUpIcon"
          size="18"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
// import cloneDeep from 'lodash/cloneDeep'

import { isDomesticFlight } from '@/constants/selectOptions'
import store from '@/store'

import {
  convertISODateTime,
  formatCurrency,
  formatDateMonth,
  getArrayDatesBetween,
  convertISODateTimeToLLLL,
  resolveBorderColorByAirline,
} from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BCard,
    BButton,
    Swiper,
    SwiperSlide,
  },
  setup(_, { emit }) {
    const {
      tabIndex,
      FLIGHT_APP_STORE_MODULE_NAME,
      fetchCheapestFare,
      searchFlightv2,
    } = useBookingHandle()
    const DAY_RANGE = 7
    const initialSlideArray = ref([])
    const initialSlide = ref()
    const swiperRef = ref()

    // const intervalId = ref()
    function handleSlideTo(slide) {
      if (swiperRef.value && slide) {
        swiperRef.value.$swiper.slideTo(slide, 100, false)
      }
      // else {
      //   let retryCount = 0
      //   const MAX_RETRY_COUNT = 10

      //   // swiperCheapFareRef chưa được khởi tạo => thử lại sau mỗi giây trong MAX_RETRY_COUNT giây
      //   intervalId.value = setInterval(() => {
      //     if (retryCount === MAX_RETRY_COUNT) {
      //       clearInterval(intervalId.value)
      //     } else {
      //       retryCount += 1
      //       console.warn(`${retryCount}. Wait for slide initialization...`)

      //       if (swiperRef.value) {
      //         initialSlide.value = initialSlideArray.value[tabIndex.value]

      //         clearInterval(intervalId.value)
      //         handleSlideTo(initialSlide.value)
      //       }
      //     }
      //   }, 1000)
      // }
    }
    // onBeforeUnmount(() => {
    //   if (intervalId.value) {
    //     clearInterval(intervalId.value)
    //   }
    // })

    const swiperOptions = computed(() => ({
      // freeMode: true,
      gridMode: true,
      centeredSlides: false,
      grabCursor: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 3,
          slidesPerGroup: 1,
        },
        540: {
          slidesPerView: 4,
          slidesPerGroup: 1,
        },
        640: {
          slidesPerView: 5,
          slidesPerGroup: 1,
        },
        960: {
          slidesPerView: 6,
          slidesPerGroup: 1,
        },
        1200: {
          slidesPerView: 7,
          slidesPerGroup: 1,
        },
      },
      spaceBetween: 5,
      initialSlide: initialSlide.value,
      // initialSlide: DAY_RANGE - 1,
    }))

    const appBreakPointComputed = computed(() => store.getters['app/currentBreakPoint'])
    const halfOfSlidePerView = computed(() => {
      let size = 1200
      switch (appBreakPointComputed.value) {
        case 'xl':
          size = 1200
          break
        case 'lg':
          size = 960
          break
        case 'md':
          size = 640
          break
        case 'sm':
          size = 540
          break
        case 'xs':
          size = 320
          break

        default:
          break
      }
      return Math.floor(swiperOptions.value.breakpoints[size].slidesPerView / 2)
    })

    const searchFlightArray = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlightArray`]())

    const searchFlightDateArray = computed(() => store.getters[
      `${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlightArray`
    ]().map(i => formatDateMonth(i.departDate, 'YYYY-MM-DD')))

    const cheapestFare = ref()
    const tempCheapestFare = ref([])
    const visibleCheapestFare = ref()

    async function fetchCheapFare() {
      visibleCheapestFare.value = JSON.parse(localStorage.getItem('visibleCheapestFare') || 'true')
      emit('updateToggleCheapest', visibleCheapestFare.value)
      cheapestFare.value = null
      tempCheapestFare.value = []
      const searchFlightDateArray = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlightArray`]()
      const values = await Promise.all(
        searchFlightDateArray.map((item, index) => {
          const departDate = new Date(item.departDate)
          const startDate = new Date(
            departDate.getFullYear(),
            departDate.getMonth(),
            departDate.getDate() - DAY_RANGE,
          )
          // if (startDate < new Date()) {
          //   startDate = new Date()
          // }
          const endDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate() + DAY_RANGE * 2,
          )
          const payload = {
            departure: item.startPoint,
            arrival: item.endPoint,
            startDate: formatDateMonth(startDate, 'YYYY-MM-DD'),
            endDate: formatDateMonth(endDate, 'YYYY-MM-DD'),
          }
          tempCheapestFare.value[index] = getArrayDatesBetween(
            payload.startDate,
            payload.endDate,
          )
          return fetchCheapestFare(payload)
        }),
      )
      const valuesDate = values.map(val => val.data)
      tempCheapestFare.value.forEach((item, index) => {
        const valArray = valuesDate[index]
        item.forEach((itemDate, indexDate) => {
          const r = valArray.find(v => v.date === itemDate.date)
          if (r) tempCheapestFare.value[index][indexDate] = r
        })
      })
      cheapestFare.value = tempCheapestFare.value
      cheapestFare.value.forEach((cheapItem, cheapIndex) => {
        const depDate = new Date(searchFlightDateArray[cheapIndex].departDate)
        const departureDate = convertISODateTime(depDate).dateFilter
        cheapItem.forEach((item, itemIndex) => {
          if (item?.cheapestFare?.length) {
            item.cheapestFare = item.cheapestFare.filter(i => ['VU', 'VJ', 'VN', 'QH'].includes(i.airline))
            const itemDate = item.date
            if (itemDate === departureDate) {
              initialSlideArray.value[cheapIndex] = itemIndex - halfOfSlidePerView.value
            }
          }
        })
      })
    }

    const statusJobDone = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getStatusJobDone`])
    watch(statusJobDone, () => {
      if (statusJobDone.value) {
        fetchCheapFare()
      }
    })

    onMounted(() => {
      fetchCheapFare()
    })

    // search lại thì call lại fetchCheapFare
    watch(
      searchFlightDateArray,
      () => {
        fetchCheapFare()
      },
      { deep: true },
    )

    watch(
      [tabIndex, swiperRef],
      () => {
        if (tabIndex.value >= 0 && swiperRef.value) {
          initialSlide.value = initialSlideArray.value[tabIndex.value] ?? (DAY_RANGE - halfOfSlidePerView.value)
          handleSlideTo(initialSlide.value)
        }
      },
      { immediate: true },
    )

    const handleClick = (date, tabIndex, index) => {
      initialSlideArray.value[tabIndex] = index - halfOfSlidePerView.value
      const searchFlightPayload = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlight`]
      if (searchFlightPayload.type === 'OW') {
        searchFlightPayload.flights[0].departDate = date
      } else if (searchFlightPayload.type === 'RT') {
        if (tabIndex === 0) searchFlightPayload.flights[0].departDate = date
        else if (tabIndex === 1) {
          searchFlightPayload.flights[0].returnDate = date
        }
      } else if (searchFlightPayload.type === 'MC') {
        searchFlightPayload.flights[tabIndex].departDate = date
      }
      searchFlightv2(searchFlightPayload, true)
    }

    // function getMinTotalFare(array) {
    //   return sortBy(
    //     array,
    //     i => i.totalFare,
    //   )[0]
    // }

    // function handleToggleVisibleCheapestFare() {
    //   visibleCheapestFare.value = visibleCheapestFare.value !== true
    //   localStorage.setItem('visibleCheapestFare', visibleCheapestFare.value)
    // }

    const isShowAll = ref(false)

    function handleShowHide() {
      isShowAll.value = !isShowAll.value
    }

    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    // Biến check có show giá rẻ hay ko (ko với F3 và quốc tế)
    const isShowCheapFare = computed(() => !isRoleF3.value && isDomesticFlight(searchFlightArray.value[tabIndex.value]?.startPoint, searchFlightArray.value[tabIndex.value]?.endPoint))
    return {
      isShowAll,
      handleShowHide,
      convertISODateTime,
      formatCurrency,
      searchFlightDateArray,
      handleClick,
      tabIndex,
      cheapestFare,
      convertISODateTimeToLLLL,
      swiperOptions,
      visibleCheapestFare,
      resolveBorderColorByAirline,
      swiperRef,
      isRoleF3,
      statusJobDone,
      isDomesticFlight,
      searchFlightArray,
      isShowCheapFare,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';

.swiper-button-prev {
  margin-top: 0;
  translate: -10% -50%;

  &:after {
    background-image: unset !important;
  }
}

.swiper-button-next {
  margin-top: 0;
  translate: 10% -50%;

  &:after {
    background-image: unset !important;
  }
}

.swiper-wrapper ::v-deep {
  transform: none !important;
}
</style>
